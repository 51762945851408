@import url("../../index.scss");

.wrap {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: #0000006c;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 8px solid #202932;
  border-top: 8px solid #dba008;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
