$primary: #202932;
$secondary: #dba008;

.bg-pmy {
  background-color: $primary;
}
.bg-scy {
  background-color: $secondary;
}

.text-pmy {
  color: $primary;
}

.text-scy {
  color: $secondary;
}

.logo-image {
  width: 50%;
}

.whitemenu {
  color: #ffffff;
}
.yellowMenu {
  color: $secondary;
}
.redMenu {
  color: red;
}
