@import '../../App.scss';

@keyframes slide {
    0% { transform: translateX(-100vw); }
    100% { transform: translateX(0); }
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    img{
        width: 300px;
    }
}

.loader-image {
    animation: slide 2s ease-in-out;
}
