@import url("../node_modules/primereact/resources/themes/saga-blue/theme.css");
@import url("../node_modules/primereact/resources/primereact.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url('../node_modules/primeflex/primeflex.css');
@import url('../node_modules/primeicons/primeicons.css');
        
body{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  font-size: 11px;
}
a{
  text-decoration: none;
  color: inherit;
}
$primary : #202932;
$secondary : #DBA008;

.primary{
  background-color: $primary;
}
.p-datatable,.p-input,.p-button,.p-dropdown,.p-dropdown-panel,.p-dropdown-items,.p-dropdown-item,.p-dropdown,.p-dropdown-label,.p-placeholder{
  font-size: 11px !important;
}

.p-button{
  font-weight: 600;
}

.p-component {
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  font-weight: 600;
}
.p-calendar .p-inputtext {
  font-size: 11px !important;
}

.p-datatable .p-datatable-thead > tr > th{
  background-color: $primary;
  color: #fff;
  border-radius: 7px 7px 0 0;
  padding: 0.7rem !important;
  text-transform: uppercase !important;
}
.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase !important;
}

.p-datatable table>tbody>tr>td,
.p-datatable table>thead>tr>th {
  border-color: $primary;
  min-width: 120px;
}

.p-datatable table>tbody>tr>td {
  padding: 0.3rem !important;
  background-color: #fff;
}

.active-link{
  // color: $secondary;
  border-left: 4px solid $secondary;
  transition: all 0.3s ease-in-out;
  background-color: #069999;
}

.non-active-link{
  border-left: 4px solid $primary;
  transition: all 0.3s ease-in-out;
}

.non-active-link:hover{
  // color: $secondary;
  background-color: #000000;
  transition: all 0.3s ease-in-out;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.p-disabled, .p-component:disabled {
  opacity: 0.7 !important;
  // color: #878787;
  // border: 1px transparent !important;
}
.p-checkbox .p-component .p-highlight .p-disabled{
  opacity: 0.2;
}
.rel{
  position: relative;
  .abs{
    position: absolute;
    right: 0;
    background-color: #d1d1d17e;
    color: $primary;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 0 3px 3px 0;
  }
}

.red{
  background-color: rgb(255, 173, 173) !important;
}

.green{
  background-color: rgb(173, 255, 177) !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  background-color: inherit !important;
}

.p-datatable .p-datatable-tbody > tr {
  background-color: #fff ;
}
