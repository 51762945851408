.navbar{
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 1;
    img{
        height: 40px;
    }
    .logo{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
    }
    a{
        text-decoration: none;
    }
    .ip{
        border: 0 !important;
        outline: none !important;
        // border-radius: 50px;
    }
    .ip:focus{
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;
    }
}